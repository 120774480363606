import React, { useState, useEffect } from "react";
import { DictEquipment } from "../../../types/types";
import DictEquipmentService from '../../../services/dict/DictEquipmentService';
import AddDictEquipmentPerUser from "./AddDictEquipment";
import Options from "./Options";
import LanguageModal from "./LanguageModal";
import { useTranslation } from "react-i18next";
import AuthService from '../../../services/auth.service';
import IUser from '../../../types/user.type';
import Modal from "../../../constants/Modal";

const ShowDictEquipment: React.FC = () => {
    const [dictEquipment, setDictEquipment] = useState<DictEquipment[]>([]);
    const [showBasic, setShowBasic] = useState(true);
    const [showPerUser, setShowPerUser] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalAddItemVisible, setModalAddItemVisible] = useState(false);
    const [selectedEquipmentId, setSelectedEquipmentId] = useState<string | null>(null);
    const { t } = useTranslation("global");
    const [currentUser, setCurrentUser] = useState<IUser | null>(null);

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
        }
        loadDictEquipment();
    }, []);

    const loadDictEquipment = () => {
        DictEquipmentService.getDictEquipment()
            .then(response => {
                setDictEquipment(response.data);
            })
            .catch(error => {
                console.error('Error loading dict equipment:', error);
            });
    };

    const hasRole = (role: string): boolean => {
        return currentUser?.roles?.includes(role) ?? false;
    };

    const handleAddEquipment = (newEquipment: DictEquipment) => {
        setDictEquipment(prevEquipment => [...prevEquipment, newEquipment]);
        setModalAddItemVisible(false); // Zamknięcie modala po dodaniu nowego elementu
    };

    const handleDeleteEquipment = (equipmentId: string) => {
        setDictEquipment(prevEquipment => prevEquipment.filter(equipment => equipment.dict_id !== equipmentId));
    };

    const handleShowBasicChange = () => {
        setShowBasic(!showBasic);
    };

    const handleShowPerUserChange = () => {
        setShowPerUser(!showPerUser);
    };

    const handleNameDoubleClick = (id: string, dict: string) => {
        setSelectedEquipmentId(id);
        setModalVisible(true);
    };

    const filteredEquipment = dictEquipment
        .filter(equipment => {
            if (showBasic && equipment.dict === "BASIC") return true;
            if (showPerUser && equipment.dict === "PER_USER") return true;
            return false;
        })
        .sort((a, b) => a.name.localeCompare(b.name)); // Sortowanie alfabetyczne

    return (
        <div>
            <label>
                <input
                    type="checkbox"
                    checked={showBasic}
                    onChange={handleShowBasicChange}
                />
                {t('dictionaries.basic')}
            </label>
            <label>
                <input
                    type="checkbox"
                    checked={showPerUser}
                    onChange={handleShowPerUserChange}
                />
                {t('dictionaries.per_user')}
            </label>

            <button onClick={() => setModalAddItemVisible(true)} className="add-button">
                {t("buttons.add")}
            </button>

            <table style={{ borderCollapse: 'collapse' }} className="fixed-table">
                <thead>
                    <tr>
                        <th style={{ textAlign: 'left', padding: '0.5rem' }}>{t("table.name")}</th>
                        <th style={{ textAlign: 'left', padding: '0.5rem' }}>{t("table.options")}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredEquipment.map((row) => (
                        <tr key={row.id}>
                            <td
                                style={{
                                    padding: '0.5rem',
                                    cursor: 'pointer',
                                    color: 'inherit',
                                }}
                            >
                                {row.name}
                            </td>
                            <td style={{ padding: '0.5rem' }}>
                                {(hasRole("ROLE_ADMIN") && row.dict === "BASIC") ? (
                                    <Options item={row.dict_id} dict={row.dict} onDeleteEquipment={handleDeleteEquipment} onEditEquipment={handleNameDoubleClick} />
                                ) : (hasRole("ROLE_TRAINER") || hasRole("ROLE_USER")) && row.dict === "PER_USER" ? (
                                    <Options item={row.dict_id} dict={row.dict} onDeleteEquipment={handleDeleteEquipment} onEditEquipment={handleNameDoubleClick} />
                                ) : (
                                    t("dictionaries.options_unavailable")
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal show={modalAddItemVisible} onClose={() => setModalAddItemVisible(false)} title={t("modals.add_dict_quantity_type")}>
                <AddDictEquipmentPerUser dictEquipment={dictEquipment} onAddEquipment={handleAddEquipment} />
            </Modal>
            <LanguageModal
                show={modalVisible}
                onClose={() => setModalVisible(false)}
                equipmentId={selectedEquipmentId!}
            />
        </div>
    );
};

export default ShowDictEquipment;
