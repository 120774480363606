import React, { Component } from "react";
import TrainingService from '../../../../services/trainings/TrainingsService';
import { withTranslation } from "react-i18next";

type Props = {
  trainingId: number;
  onTrainingOptionDelete: (trainingId: number) => void;
  t: any;
};
type State = {};

class OptionsDelete extends Component<Props, State> {
    handleDelete = async () => {
        const { trainingId, onTrainingOptionDelete, t } = this.props; 
        const confirmMessage = `${t('buttons.delete_session_row')}`;

        if (window.confirm(confirmMessage)) {
            try {
                await TrainingService.deleteTraining(trainingId);
                onTrainingOptionDelete(trainingId); 
            } catch (error) {
                console.error('Error deleting exercise:', error);
            }
        }
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <label htmlFor={t("buttons.delete")} className="icon-option">
                    <img src="/images/navigation/bin.svg" onClick={this.handleDelete} alt={t("buttons.delete")} />
                </label>
            </div>
        );
    }
}

export default withTranslation("global")(OptionsDelete);

