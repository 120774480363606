const URL_RENDER = "https://zapiszto-service.onrender.com/v1";
const URL_LOCALHOST = "http://localhost:8080/v1"
const URL_AUTH_LOCALHOST = "http://localhost:8080/api/auth/";
const URL_AUTH_RENDER = "https://zapiszto-service.onrender.com/api/auth/";
const RECAPTHCA_LOCALHOST = '6LfpUvgpAAAAAH4mqrcWNgVcavvJCLDjN6Tr2Arv'
                        
export const RECAPTCHA_SITE_KEY = RECAPTHCA_LOCALHOST



// export const API_URL = URL_LOCALHOST;
// export const AUTH_URL = URL_AUTH_LOCALHOST;

export const API_URL = URL_RENDER;
export const AUTH_URL = URL_AUTH_RENDER;
