import React, { useState } from "react";
import DictUnitsService from "../../../services/dict/DictUnitsService";
import { withTranslation } from "react-i18next";
import Modal from "../../../constants/Modal";
type Props = {
    item: string;
    onDeleteUnit: (unitId: string) => void;
    onEditUnit: (unitId: string, dict: string) => void;
    dict: string;
    t: any;
};

const Options: React.FC<Props> = ({ item, onDeleteUnit, onEditUnit, dict, t }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    
    const openOptionsModal = () => {
        setModalTitle(t("modals.optionsTitle"));
        setShowModal(true);
    };

    const deleteUnit = (itemId: string) => {
        DictUnitsService.deleteDictUnit(itemId)
            .then(() => {
                onDeleteUnit(itemId);
                setShowModal(false);
            })
            .catch(error => {
                console.error('Error deleting unit:', error);
            });
    };

    return (
        <div>
            <img src="/images/navigation/options.svg" alt="Options" style={{ cursor: "pointer", width: "24px", height: "24px" }} onClick={openOptionsModal} />
            
            <Modal show={showModal} onClose={() => setShowModal(false)} title={modalTitle}> 
                <div>
                    <button onClick={() => deleteUnit(item)}>{t("buttons.delete")}</button>
                    <button onClick={() => { onEditUnit(item, dict); setShowModal(false); }}>{t("buttons.edit")}</button>
                </div>
            </Modal>
        </div>
    );
};

export default withTranslation("global")(Options);
