import React, { Component } from 'react';
import { Workbook } from '../../../../types/types';
import Service from '../../../../services/workbooks'
import { DictWorkbookSchema } from '../../../../types/types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withTranslation } from "react-i18next";


type Props = {
  workbook: Workbook;
  t: any;
};

type State = {
  dictWorkbookSchema: DictWorkbookSchema[];
  selectedSchemaName: string; // Zmień na selectedSchemaId
}

class ControlsSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dictWorkbookSchema: [],
      selectedSchemaName: '' // Zmień na selectedSchemaId
    }
  }

  componentDidMount() {
    Service.getDictWorkbookSchema()
      .then(response => {
        const dictWorkbookSchema: DictWorkbookSchema[] = response.data;
        this.setState({ dictWorkbookSchema });
      })
      .catch(error => {
        console.error('Error fetching dictWorkbookSchema:', error);
      });
  }

  handleDeleteClick = () => {
    const id = this.props.workbook.id.valueOf();
    Service.deleteWorkbook(id)
      .then(() => {
        console.log('Workbook deleted successfully');
        window.location.reload();
      })
      .catch(error => {
        console.error('Error deleting workbook:', error);
      });
  }

  handleUpdateDictWorkbookSchema = (values: { selectedSchemaName: string }) => {
    const { selectedSchemaName } = values;
    const { id } = this.props.workbook;
    const selectedSchema = this.state.dictWorkbookSchema.find(schema => schema.name === selectedSchemaName);
    if (selectedSchema) {
      const selectedSchemaId = selectedSchema.id;
      Service.putWorkbookSchemaId(id, selectedSchemaId)
        .then(() => {
          console.log('Workbook schema updated successfully');
          window.location.reload();
        })
        .catch(error => {
          console.error('Error updating workbook schema:', error);
        });
    } else {
      console.error('Selected schema not found');
    }
  }

  render() {
    const { workbook, t } = this.props;
    const { dictWorkbookSchema, selectedSchemaName } = this.state;


    return (
      <div style={{display: 'flex'}}>
        <div  style={{flex: '1'}}>
          <p>{t("workbooks.start_date")}: {workbook.insert_date}</p>
        </div>
        <div style={{ flex: '1', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <button className='buttonDelete' onClick={this.handleDeleteClick}>{t("buttons.delete")} {workbook.name}</button>
        </div>
      </div>
    );
  }
}

export default withTranslation("global")(ControlsSection)
