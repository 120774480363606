import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Service from "../../services/bodyParams";
import PostAge from "./postAge";
import Modal from "../../constants/Modal";

type Props = {
    updateData: () => void;  // Dodajemy odbiór funkcji updateData
};

const Age: React.FC<Props> = ({ updateData }) => {
    const [age, setAge] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { t } = useTranslation("global");

    useEffect(() => {
        getAge();
    }, []);

    const getAge = () => {
        Service.getAge()
            .then(response => {
                if (response.status === 200) {
                    const age = response.data.age;
                    setAge(age);
                    setLoading(false);
                } else if (response.status === 204) {
                    setAge(null);
                    setLoading(false);
                } else {
                    console.log("Unexpected status code:", response.status);
                    setLoading(false);
                }
            })
            .catch(error => {
                console.log("Error fetching age:", error);
                setLoading(false);
            });
    };

    const refreshAge = () => {
        getAge();
        updateData();
        setIsModalOpen(false); // Zamknij modal po aktualizacji
    };

    if (loading) {
        return <p><span className="loader" /></p>;
    }

    return (
        <div>
            <button onClick={() => setIsModalOpen(true)}>{t("buttons.update")}</button>

            <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)} title={t("body_params.update_age")}>
                <PostAge updateData={refreshAge} />
            </Modal>
        </div>
    );
};

export default Age;
