import React, { Component } from "react";
import TrainingService from '../../../../services/trainings/TrainingsService';
import { withTranslation } from "react-i18next";

type Props = {
  trainingId: number;
  onTrainingOptionCopy: () => void; // Funkcja do odświeżenia listy
  t: any;
};

class OptionsCopy extends Component<Props> {
    handleCopy = async () => {
        const { trainingId, onTrainingOptionCopy, t } = this.props; 
        const confirmMessage = `${t('buttons.copy_training')}`;

        if (window.confirm(confirmMessage)) {
            try {
                await TrainingService.copyTraining(trainingId);
                onTrainingOptionCopy(); // Aktualizacja listy po skopiowaniu
            } catch (error) {
                console.error('Error copying exercise:', error);
            }
        }
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <label htmlFor={t("buttons.copy")} className="icon-option">
                    <img src="/images/navigation/copy.svg" onClick={this.handleCopy} alt={t("buttons.copy")} />
                </label>
            </div>
        );
    }
}

export default withTranslation("global")(OptionsCopy);
