import React, { useState, useEffect } from "react";
import { DictBodyTest } from "../../../types/types";
import DictBodyTestService from '../../../services/dict/DictBodyTestService';
import AddDictBodyTestPerUser from "./AddDictBodyTest";
import Options from "./Options";
import LanguageModal from "./LanguageModal";
import { useTranslation } from "react-i18next";
import Modal from "../../../constants/Modal";

const ShowDictBodyTest: React.FC = () => {
    const [dictBodyTests, setDictBodyTests] = useState<DictBodyTest[]>([]);
    const [showBasic, setShowBasic] = useState(true);
    const [showPerUser, setShowPerUser] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalAddItemVisible, setModalAddItemVisible] = useState(false);
    const [selectedBodyTestId, setSelectedBodyTestId] = useState<string | null>(null);
    const { t } = useTranslation("global");

    useEffect(() => {
        loadDictBodyTests();
    }, []);

    const loadDictBodyTests = () => {
        DictBodyTestService.getDictBodyTest()
            .then(response => {
                setDictBodyTests(response.data);
            })
            .catch(error => {
                console.error('Error loading dict body tests:', error);
            });
    };

    const handleAddBodyTest = (newBodyTest: DictBodyTest) => {
        setDictBodyTests(prevBodyTests => [...prevBodyTests, newBodyTest]);
        setModalAddItemVisible(false); // Zamknięcie modala po dodaniu nowego elementu
    };

    const handleDeleteBodyTest = (bodyTestId: string) => {
        setDictBodyTests(prevBodyTests => prevBodyTests.filter(bodyTest => bodyTest.dict_id !== bodyTestId));
    };

    const handleShowBasicChange = () => {
        setShowBasic(!showBasic);
    };

    const handleShowPerUserChange = () => {
        setShowPerUser(!showPerUser);
    };

    const handleNameDoubleClick = (id: string, dict: string) => {
        setSelectedBodyTestId(id);
        setModalVisible(true);
    };

    const filteredBodyTests = dictBodyTests.filter(bodyTest => {
        if (showBasic && bodyTest.dict === "BASIC") return true;
        if (showPerUser && bodyTest.dict === "PER_USER") return true;
        return false;
    });

    return (
        <div>
            <label>
                <input
                    type="checkbox"
                    checked={showBasic}
                    onChange={handleShowBasicChange}
                />
                {t('dictionaries.basic')}
            </label>
            <label>
                <input
                    type="checkbox"
                    checked={showPerUser}
                    onChange={handleShowPerUserChange}
                />
                {t('dictionaries.per_user')}
            </label>

            <button onClick={() => setModalAddItemVisible(true)} className="add-button">
                {t("buttons.add")}
            </button>

            <table style={{ borderCollapse: 'collapse' }} className="fixed-table">
                <thead>
                    <tr>
                        <th>{t("table.name")}</th>
                        <th>{t("table.description")}</th>
                        <th>{t("table.purpose")}</th>
                        <th>{t("table.options")}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredBodyTests.map((row) => (
                        <tr key={row.id}>
                            <td>{row.name}</td>
                            <td>{row.description}</td>
                            <td>{row.purpose}</td>
                            <td>
                                {row.dict === "PER_USER" ? (
                                    <Options item={row.dict_id} dict={row.dict} onDeleteBodyTest={handleDeleteBodyTest} onEditBodyTest={handleNameDoubleClick} />
                                ) : (
                                    t("dictionaries.options_unavailable")
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal show={modalAddItemVisible} onClose={() => setModalAddItemVisible(false)} title={t("modals.add_dict_quantity_type")}>
                <AddDictBodyTestPerUser dictBodyTests={dictBodyTests} onAddBodyTest={handleAddBodyTest} />
            </Modal>
            <LanguageModal
                show={modalVisible}
                onClose={() => setModalVisible(false)}
                bodyTestId={selectedBodyTestId!}
            />
        </div>
    );
};

export default ShowDictBodyTest;
