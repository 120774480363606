import React, { useState, useEffect  } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import BodyParamsService from '../../services/bodyParams';
import { useTranslation } from 'react-i18next';
import { BodyParamDto } from '../../types/types';
import Modal from '../../constants/Modal';

type Props = {
  clientId: string | null;
  updateData?: () => void;
};

const PutBodyParam: React.FC<Props> = ({ clientId, updateData }) => {
  const { t } = useTranslation('global');
  const [bodyParameters, setBodyParameters] = useState<{ id: string; name: string }[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    BodyParamsService.getDictBodyParams().then((response) => {
      setBodyParameters(response.data);
    });
  }, []);

  const validationSchema = Yup.object().shape({
    dictBodyParam: Yup.number().required(t('validation.this_field_is_required')),
    value: Yup.number().required(t('validation.this_field_is_required')),
  });

  const handlePost = (formValue: { dictBodyParam: string; value: string }, { resetForm }: any) => {
    const bodyParamDto: BodyParamDto = {
      dict_body_params_id: Number(formValue.dictBodyParam),
      value: formValue.value,
      clientId: clientId || '',
    };

    BodyParamsService.postBodyParam(bodyParamDto).then(() => {
      if (updateData) updateData();
      resetForm();  // Reset formularza
      setIsModalOpen(false); // Zamknięcie modala
    });
  };

  return (
    <div>
      {/* Przycisk otwierający modal */}
      <button onClick={() => setIsModalOpen(true)} className="btn btn-primary">
        {t('buttons.add')}
      </button>

      {/* Modal */}
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)} title={t('body_params.add_body_param')}>
        <Formik initialValues={{ dictBodyParam: '', value: '' }} validationSchema={validationSchema} onSubmit={handlePost}>
          {({ isSubmitting }) => (
            <Form>
              <div className="form-container">
                <div className="form-field-container">
                  <Field as="select" name="dictBodyParam" className="select-field">
                    <option value="" disabled>{t('buttons.select_attribute')}</option>
                    {bodyParameters.map((param) => (
                      <option key={param.id} value={param.id}>{param.name}</option>
                    ))}
                  </Field>
                  <ErrorMessage name="dictBodyParam" component="div" className="error" />
                </div>

                <div className="form-field-container">
                  <Field name="value" type="text" className="form-field" placeholder={t('table.value')} />
                  <ErrorMessage name="value" component="div" className="error" />
                </div>

                <div className="form-field-container">
                  <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    {t('buttons.add')}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default PutBodyParam;
