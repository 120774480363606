import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Service from "../../services/bodyParams";

type Props = {
  updateData: () => void; // Funkcja do odświeżania danych
};

const PostBirthDate: React.FC<Props> = ({ updateData }) => {
  const { t } = useTranslation("global");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedDate) {
      Service.postBirthDate(selectedDate)
        .then(() => {
          console.log("Wysłano datę:", selectedDate);
          updateData(); // Odśwież dane po pomyślnym wysłaniu
        })
        .catch((error) => {
          console.error("Błąd podczas wysyłania daty:", error);
        });
    } else {
      console.log("Wybierz datę urodzenia.");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>{t("buttons.select_birthdate")}:</label>
        <br />
        <DatePicker
          selected={selectedDate}
          onChange={(date: Date | null) => setSelectedDate(date)}
          dateFormat="yyyy-MM-dd"
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={100} // Możliwość wyboru 100 lat wstecz
        />
        <br />
        <button type="submit">{t("buttons.add")}</button>
      </form>
    </div>
  );
};

export default PostBirthDate;
