import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import DiagramService from "../../../../../../services/diagrams/DiagramService";
import { ExerciseStats } from "../../../../../../types/types";

interface DiagramProps {
  mesocycleId: string;
}

const Diagram: React.FC<DiagramProps> = ({ mesocycleId }) => {
  const [data, setData] = useState<{ repetitions: (string | number | null)[][]; sets: (string | number | null)[][] }>({ repetitions: [], sets: [] });
  const [categories, setCategories] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await DiagramService.getExerciseStats(mesocycleId);
        const fetchedData: ExerciseStats[] = response.data;

        if (fetchedData.length === 0) {
          setIsLoading(false);
          return;
        }

        // Pobranie unikalnych kategorii
        const uniqueCategories = Array.from(new Set(fetchedData.map(item => item.category)));
        setCategories(uniqueCategories);

        // Nagłówki tabeli
        const repetitionsHeaders = ["Order ID", ...uniqueCategories.map(category => `${category} Repetitions`)];
        const setsHeaders = ["Order ID", ...uniqueCategories.map(category => `${category} Sets`)];

        // Znalezienie maksymalnego orderId
        const maxOrderId = fetchedData.length > 0 ? Math.max(...fetchedData.map(item => item.orderId)) : 0;

        // Tworzenie pustych wierszy dla każdego orderId
        const repetitionsData: (string | number | null)[][] = [repetitionsHeaders];
        const setsData: (string | number | null)[][] = [setsHeaders];

        for (let i = 1; i <= maxOrderId; i++) {
          const repRow: (string | number | null)[] = [i];
          const setRow: (string | number | null)[] = [i];

          uniqueCategories.forEach(category => {
            const item = fetchedData.find(data => data.orderId === i && data.category === category);
            repRow.push(item ? item.repetitions : null);
            setRow.push(item ? item.sets : null);
          });

          repetitionsData.push(repRow);
          setsData.push(setRow);
        }

        setData({ repetitions: repetitionsData, sets: setsData });
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [mesocycleId]);

  const repetitionsOptions = {
    title: "Repetitions per Category",
    hAxis: { title: "Order ID" },
    vAxis: { title: "Repetitions" },
    series: categories.map(() => ({ lineWidth: 2 })),
  };

  const setsOptions = {
    title: "Sets per Category",
    hAxis: { title: "Order ID" },
    vAxis: { title: "Sets" },
    series: categories.map(() => ({ lineWidth: 2 })),
  };

  return (
    <div>
      {isLoading && <p>Loading data...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {!isLoading && !error && (
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "20px" }}>
          <div style={{ flex: "1 1 500px", minWidth: "300px" }}>
            <Chart
              chartType="LineChart"
              width="100%"
              height="300px"
              data={data.repetitions}
              options={repetitionsOptions}
            />
          </div>
          <div style={{ flex: "1 1 500px", minWidth: "300px" }}>
            <Chart
              chartType="LineChart"
              width="100%"
              height="300px"
              data={data.sets}
              options={setsOptions}
            />
          </div>
        </div>
      )}
    </div>
  );
  
};

export default Diagram;
