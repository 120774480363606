import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Service from "../../services/bodyParams";

type PostGenderProps = {
  onGenderUpdate: () => void;
};

const PostGender: React.FC<PostGenderProps> = ({ onGenderUpdate }) => {
  const [gender, setGender] = useState<string>("");
  const { t } = useTranslation("global");

  const handleGenderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setGender(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (gender) {
      try {
        await Service.postSex(gender);
        onGenderUpdate();
      } catch (error) {
        console.error("Error updating gender:", error);
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>{t("buttons.select_gender")}:</label>
        <select value={gender} onChange={handleGenderChange}>
          <option value="">{t("buttons.select")}</option>
          <option value="Male">{t("buttons.male")}</option>
          <option value="Female">{t("buttons.female")}</option>
        </select>
        <button type="submit">{t("buttons.add")}</button>
      </form>
    </div>
  );
};

export default PostGender;
