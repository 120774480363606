import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Client, Goal } from '../../../../types/types';
import AddGoals from './AddGoals';
import GetGoals from './GetGoals';
import GoalsService from '../../../../services/goals/GoalsService';

interface ClientDetailsProps {
  client: Client;
}

const ClientGoals: React.FC<ClientDetailsProps> = ({ client }) => {
  const { t } = useTranslation('global');
  const [goals, setGoals] = useState<Goal[]>([]);

  useEffect(() => {
    loadGoals();
  }, [client.id]);

  const loadGoals = () => {
    GoalsService.getGoals(client.id)
      .then(response => {
        setGoals(response.data);
      })
      .catch(error => {
        console.error('Error loading goals:', error);
      });
  };

  return (
    <div>
      <GetGoals goals={goals} />
      <AddGoals client={client} onGoalAdded={loadGoals} />
    </div>
  );
};

export default ClientGoals;
