import React, { useState, useEffect } from "react";
import { DictUnits } from "../../../types/types";
import DictUnitsService from '../../../services/dict/DictUnitsService';
import AddDictUnitPerUser from "./AddDictUnit";
import Options from "./Options";
import LanguageModal from "./LanguageModal";
import { useTranslation } from "react-i18next";
import AuthService from '../../../services/auth.service';
import IUser from '../../../types/user.type';
import Modal from "../../../constants/Modal";

const ShowDictUnits: React.FC = () => {
    const [dictUnits, setDictUnits] = useState<DictUnits[]>([]);
    const [showBasic, setShowBasic] = useState(true);
    const [showPerUser, setShowPerUser] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalAddItemVisible, setModalAddItemVisible] = useState(false);
    const [selectedUnitId, setSelectedUnitId] = useState<string | null>(null);
    const { t } = useTranslation("global");
    const [currentUser, setCurrentUser] = useState<IUser | null>(null);

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
        }
        loadDictUnits();
    }, []);

    const loadDictUnits = () => {
        DictUnitsService.getDictUnits()
            .then(response => {
                setDictUnits(response.data);
            })
            .catch(error => {
                console.error('Error loading dict units:', error);
            });
    };

    const handleAddUnit = (newUnit: DictUnits) => {
        setDictUnits(prevUnits => [...prevUnits, newUnit]);
        setModalAddItemVisible(false); // Zamknięcie modala po dodaniu nowego elementu
    };

    const handleDeleteUnit = (unitId: string) => {
        setDictUnits(prevUnits => prevUnits.filter(unit => unit.dict_id !== unitId));
    };

    const handleShowBasicChange = () => {
        setShowBasic(!showBasic);
    };

    const handleShowPerUserChange = () => {
        setShowPerUser(!showPerUser);
    };

    const handleNameDoubleClick = (id: string, dict: string) => {
        setSelectedUnitId(id);
        setModalVisible(true);
    };

    const hasRole = (role: string): boolean => {
        return currentUser?.roles?.includes(role) ?? false;
    };

    const filteredUnits = dictUnits.filter(unit => {
        if (showBasic && unit.dict === "BASIC") return true;
        if (showPerUser && unit.dict === "PER_USER") return true;
        return false;
    });

    return (
        <div>
            <label>
                <input
                    type="checkbox"
                    checked={showBasic}
                    onChange={handleShowBasicChange}
                />
                {t('dictionaries.basic')}
            </label>
            <label>
                <input
                    type="checkbox"
                    checked={showPerUser}
                    onChange={handleShowPerUserChange}
                />
                {t('dictionaries.per_user')}
            </label>

            <button onClick={() => setModalAddItemVisible(true)} className="add-button">
                {t("buttons.add")}
            </button>

            <table style={{ borderCollapse: 'collapse' }} className="fixed-table">
                <thead>
                    <tr>
                        <th>{t("table.name")}</th>
                        <th>{t("table.shortcut")}</th>
                        <th>{t("table.options")}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredUnits.map((row) => (
                        <tr key={row.id}>
                            <td>{row.name}</td>
                            <td>{row.shortcut}</td>
                            <td>
                                {(hasRole("ROLE_ADMIN") && row.dict === "BASIC") ? (
                                    <Options item={row.dict_id} dict={row.dict} onDeleteUnit={handleDeleteUnit} onEditUnit={handleNameDoubleClick} />
                                ) : (hasRole("ROLE_TRAINER") || hasRole("ROLE_USER")) && row.dict === "PER_USER" ? (
                                    <Options item={row.dict_id} dict={row.dict} onDeleteUnit={handleDeleteUnit} onEditUnit={handleNameDoubleClick} />
                                ) : (
                                    t("dictionaries.options_unavailable")
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Modal show={modalAddItemVisible} onClose={() => setModalAddItemVisible(false)} title={t("modals.add_dict_quantity_type")}>
                <AddDictUnitPerUser dictUnits={dictUnits} onAddUnit={handleAddUnit} />
            </Modal>
            <LanguageModal
                show={modalVisible}
                onClose={() => setModalVisible(false)}
                unitId={selectedUnitId!}
            />
        </div>
    );
};

export default ShowDictUnits;
