import React, { useEffect, useState } from 'react';
import GoalsService from '../../../../../services/goals/GoalsService';
import { GoalDetailsDto } from '../../../../../types/types';
import { useTranslation } from "react-i18next";

type CurrentUserGoalsProps = {
  clientId: string;
};

const CurrentUserGoals: React.FC<CurrentUserGoalsProps> = ({ clientId }) => {
  const [goals, setGoals] = useState<GoalDetailsDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation("global");

  useEffect(() => {
    setGoals([]); // 🔹 Resetowanie stanu przed pobraniem nowych danych
    setLoading(true);

    GoalsService.getGoalDetails(clientId)
      .then(response => {
        setGoals(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching goal details:', error);
        setLoading(false);
      });
  }, [clientId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {goals.length === 0 ? (
        <p>{t('clients.no_goals_yet')}</p>
      ) : (
        <table style={{ minWidth: '650px', width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th>{t("table.body_param")}</th>
              <th>{t("table.body_test")}</th>
              <th>{t("table.unit")}</th>
              <th>{t("table.action")}</th>
              <th>{t("table.value")}</th>
            </tr>
          </thead>
          <tbody>
            {goals.map(goal => (
              <tr key={goal.clientId} style={{ borderBottom: '1px solid #ddd' }}>
                <td>{goal.bodyParamName || '-'}</td>
                <td>{goal.bodyTestName || '-'}</td>
                <td>{goal.unitName || '-'}</td>
                <td>{goal.action || '-'}</td>
                <td>{goal.value || '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CurrentUserGoals;
