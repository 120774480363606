import React, { Component } from 'react';
import PutBodyParam from './putBodyParam';
import ShowBodyParams from './showBodyParams';
import ShowDiagram from './showDiagramAll';
import Service from '../../services/bodyParams';

export default class BodyParams extends Component {
  state = {
    bodyParams: null,
    gender: null,
    age: null,
    bmi: null
  };

  componentDidMount() {
    this.updateData();
  }

  updateData = () => {
    Service.getActualBodyParams().then(
      (response) => {
        this.setState({
          bodyParams: response.data,
        });
      },
      (error) => {
        console.error("Error fetching body parameters:", error);
      }
    );
    Service.getGender().then(
      (response) => {
        this.setState({
          gender: response.data.gender,
        });
      },
      (error) => {
        console.error("Error fetching gender:", error);
      }
    );

    Service.getAge().then(
      (response) => {
        this.setState({
          age: response.data.age,
        });
      },
      (error) => {
        console.error("Error fetching age:", error);
      }
    );

    Service.getBmiParams()
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {  // Dodano sprawdzenie tablicy
          const bmiData = response.data[0];

          if (bmiData && bmiData.date) {
            bmiData.date = new Date(bmiData.date).toISOString().split('T')[0];
          }

          this.setState({ bmi: bmiData });
        } else {
          this.setState({ bmi: null }); // Poprawna obsługa pustej tablicy
        }
      })
      .catch((error) => {
        console.error("Błąd pobierania BMI:", error);
        this.setState({ bmi: null });
      });
  };



  render() {
    return (
      <div className="card-container">
        <ShowBodyParams
          bodyParams={this.state.bodyParams}
          gender={this.state.gender}
          age={this.state.age}
          bmi={this.state.bmi}
          updateData={this.updateData}
        />
        <PutBodyParam clientId={null} updateData={this.updateData} />
        <ShowDiagram bodyParams={this.state.bodyParams} />
      </div>
    );
  }
}
