import React, { Component } from "react";
import ExercisesTrainingService from '../../../../../services/exercises/training/ExercisesTrainingService';
import { withTranslation } from "react-i18next";

type Props = {
  exerciseId: string;
  trainingId: number;
  onExerciseUpdate: () => void; // Funkcja do odświeżenia listy
  t: any;
};
type State = {};

class OptionsMove extends Component<Props, State> {
  handleMoveUp = async () => {
    const { exerciseId, trainingId, onExerciseUpdate } = this.props;
    try {
      await ExercisesTrainingService.updateExerciseOrderNumberUp(trainingId, exerciseId);
      onExerciseUpdate(); // Aktualizuj listę
    } catch (error) {
      console.error('Error moving exercise up:', error);
    }
  };

  handleMoveDown = async () => {
    const { exerciseId, trainingId, onExerciseUpdate } = this.props;
    try {
      await ExercisesTrainingService.updateExerciseOrderNumberDown(trainingId, exerciseId);
      onExerciseUpdate(); // Aktualizuj listę
    } catch (error) {
      console.error('Error moving exercise down:', error);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <label htmlFor={t("buttons.move_up")} className="icon-option-big">
          <img src="/images/navigation/move_up.svg" onClick={this.handleMoveUp} />
        </label>
        <label htmlFor={t("buttons.move_down")} className="icon-option-big">
          <img src="/images/navigation/move_down.svg" onClick={this.handleMoveDown} />
        </label>
      </div>
    );
  }
}

export default withTranslation("global")(OptionsMove);
