import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Client, ClientBodyTest } from '../../../../types/types';
import AddNewBodyTest from './AddNewBodyTest';
import AddClientBodyTest from './AddClientBodyTest';
import GetClientBodyTest from './GetClientBodyTest';
import Modal from '../../../../constants/Modal';



interface ClientDetailsProps {
  client: Client
}

const ClientBodyTests: React.FC<ClientDetailsProps> = ({ client }) => {
  const { t } = useTranslation('global');
  const [bodyTests, setBodyTests] = useState<ClientBodyTest[]>([]); // <-- Nowy stan
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div>
      <GetClientBodyTest client={client} bodyTests={bodyTests} setBodyTests={setBodyTests} />
      <AddClientBodyTest client={client} setBodyTests={setBodyTests} />
      <button onClick={handleOpenModal}>{t('buttons.add_new_test')}</button>

      <Modal show={showModal} onClose={handleCloseModal} title={t('modal.add_new_test')}>
        <AddNewBodyTest client={client} onClose={handleCloseModal} />
      </Modal>
    </div>
  );
};

export default ClientBodyTests;