import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Client, NewClientBodyTest, DictBodyTest, ClientBodyTest } from '../../../../types/types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ClientBodyTestsService from '../../../../services/bodyTests/ClientBodyTestsService';
import DictBodyTestService from '../../../../services/dict/DictBodyTestService';

interface AddClientBodyTestProps {
    client: Client;
    setBodyTests: React.Dispatch<React.SetStateAction<ClientBodyTest[]>>;
}

const AddClientBodyTest: React.FC<AddClientBodyTestProps> = ({ client, setBodyTests }) => {
    const { t } = useTranslation('global');
    const [dictBodyTests, setDictBodyTests] = useState<DictBodyTest[]>([]);

    useEffect(() => {
        DictBodyTestService.getDictBodyTest()
            .then(response => {
                setDictBodyTests(response.data);
            })
            .catch(error => {
                console.error('Błąd podczas pobierania danych:', error);
            });
    }, []);

    const validationSchema = Yup.object({
        dictBodyTestId: Yup.string().required(t('validation.this_field_is_required')),
        result: Yup.string().required(t('validation.this_field_is_required')),
    });

    const initialValues = {
        id: crypto.randomUUID(),
        clientId: client.id,
        dictBodyTestId: '',
        result: ''
    };

    const handleSubmit = (values: { id: string; clientId: string; dictBodyTestId: string; result: string }) => {
        const requestBody: NewClientBodyTest = {
            id: values.id,
            clientId: values.clientId,
            dictBodyTestId: values.dictBodyTestId,
            result: values.result
        };

        ClientBodyTestsService.postClientBodyTestPerUser(requestBody)
            .then(response => {
                console.log('Odpowiedź z serwera:', response);

                // Pobieramy ponownie dane i aktualizujemy stan w `GetClientBodyTest`
                ClientBodyTestsService.getClientBodyTest(client.id)
                    .then(res => setBodyTests(res.data))
                    .catch(err => console.error('Błąd podczas odświeżania listy:', err));
            })
            .catch(error => {
                console.error('Błąd podczas wysyłania zapytania:', error);
            });
    };

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <div className="form-container">
                            <div className="form-field-container">
                                <ErrorMessage name="dictBodyTestId" component="div" className="error" />
                                <Field as="select" name="dictBodyTestId" className="select-field" placeholder={t('dictionaries.select_test')} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFieldValue('dictBodyTestId', e.target.value)}>
                                    <option value="" disabled>
                                        {t('dictionaries.select_test')}
                                    </option>
                                    {dictBodyTests.map(test => (
                                        <option key={test.id} value={test.id}>
                                            {test.name}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                            <div className="form-field-container">
                                <ErrorMessage name="result" component="div" className="error" />
                                <Field name="result" type="text" className="form-field" placeholder={t('dictionaries.result')} />
                            </div>
                            <button type="submit">{t('buttons.add')}</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AddClientBodyTest;
