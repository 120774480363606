import React from "react";
import Service from '../../../services/dict/DictCategoryService';
import { withTranslation } from "react-i18next";

type Props = {
    item: string;  // Zmiana typu na string (UUID)
    onDeleteCategory: (id: string) => void;  // Zmiana typu na string (UUID)
    t: any;
};

const Options: React.FC<Props> = ({ item, onDeleteCategory, t }) => {
    const deleteCategory = (item: string) => {  // Zmiana typu na string (UUID)
        Service.deleteDictCategory(item)
            .then(() => {
                onDeleteCategory(item);
            })
            .catch(error => {
                console.error('Error deleting category:', error);
            });
    }

    return (
        <div>
            <label htmlFor={t("buttons.delete")} className="icon-option">
                <img src="/images/navigation/bin.svg"  onClick={() => deleteCategory(item)} />
            </label>
        </div>
    );
}

export default withTranslation("global")(Options);
