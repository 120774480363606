import React, { Component } from "react";
import Service from "../../services/bodyParams";
import { CpfType } from "../../types/types";
import Collapsible from "react-collapsible";
import ShowSingleCpf from "./showSingleCpf";
import { withTranslation } from "react-i18next";


type Props = {
    t: any;
};
type State = {
    cpfReduction: CpfType[];
    cpfRegular: CpfType[];
    cpfMass: CpfType[];
    expandedItemIndex: number | null;
}
class GetCpf extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            cpfReduction: [],
            cpfRegular: [],
            cpfMass: [],
            expandedItemIndex: null,
        };
    }

    componentDidMount() {
        Service.getCpf().then(
            (response) => {
                console.log("Odpowiedź z API:", response.data); // Sprawdź, co zwraca backend
                if (!Array.isArray(response.data)) {
                    console.error("Błąd: Odpowiedź nie jest tablicą", response.data);
                    return;
                }

                const cpfList = response.data;
                if (cpfList.length >= 3) {
                    const [cpfReduction, cpfRegular, cpfMass] = cpfList;
                    this.setState({ cpfReduction, cpfRegular, cpfMass });
                } else {
                    console.error("Błąd: Niepoprawna struktura CPF", cpfList);
                }
            },
            (error) => {
                console.error("Błąd pobierania CPF:", error);
            }
        );
    }

    handleItemClick(index: number) {
        this.setState((prevState) => ({
            expandedItemIndex: prevState.expandedItemIndex === index ? null : index,
        }));
    }

    render() {
        const { cpfReduction, cpfRegular, cpfMass } = this.state;
        const { t } = this.props;

        if (!Array.isArray(cpfReduction) || !Array.isArray(cpfRegular) || !Array.isArray(cpfMass)) {
            return <p>Błąd ładowania danych CPF. Spróbuj ponownie później.</p>;
        }

        return (
            <div style={{ display: 'flex' }}>
                <div style={{ flex: '1' }}>
                    <p style={{ textAlign: 'center' }}>{t("body_params.cpf_reduction")}</p>
                    {cpfReduction.map((item: CpfType, index: number) => (
                        <li key={index}>
                            <Collapsible
                                trigger={
                                    <div className="cpf-box"
                                         onClick={() => this.handleItemClick(index)}
                                    >
                                        <span> {item.activity_level} </span>
                                    </div>
                                }
                            >
                                <ShowSingleCpf param_name={item} cpfType={cpfReduction}></ShowSingleCpf>
                            </Collapsible>
                        </li>
                    ))}
                </div>
                <div style={{ flex: '1' }}>
                    <p style={{ textAlign: 'center' }}>{t("body_params.cpf_regular")}</p>
                    {cpfRegular.map((item: CpfType, index: number) => (
                        <li key={index}>
                            <Collapsible
                                trigger={
                                    <div className="cpf-box"
                                         onClick={() => this.handleItemClick(index)}
                                    >
                                        <span> {item.activity_level} </span>
                                    </div>
                                }
                            >
                                <ShowSingleCpf param_name={item} cpfType={cpfRegular}></ShowSingleCpf>
                            </Collapsible>
                        </li>
                    ))}
                </div>
                <div style={{ flex: '1' }}>
                    <p style={{ textAlign: 'center' }}>{t("body_params.cpf_mass")}</p>
                    {cpfMass.map((item: CpfType, index: number) => (
                        <li key={index}>
                            <Collapsible
                                trigger={
                                    <div className="cpf-box"
                                         onClick={() => this.handleItemClick(index)}
                                    >
                                        <span> {item.activity_level} </span>
                                    </div>
                                }
                            >
                                <ShowSingleCpf param_name={item} cpfType={cpfMass}></ShowSingleCpf>
                            </Collapsible>
                        </li>
                    ))}
                </div>
            </div>
        );
    }
}

export default withTranslation("global")(GetCpf);
