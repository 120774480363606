import React, { Component } from "react";
import OptionsCopy from "./OptionsCopy";
import OptionsDelete from "./OptionsDelete";
import { withTranslation } from "react-i18next";
import Modal from "../../../../constants/Modal";

type Props = {
    trainingId: number;
    onTrainingOptionCopy: () => void;
    onTrainingOptionDelete: (trainingId: number) => void;
    t: any;
};

type State = {
    isModalOpen: boolean;
};

class Options extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };
    }

    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    render() {
        const { trainingId, onTrainingOptionCopy, onTrainingOptionDelete, t } = this.props;
        const { isModalOpen } = this.state;

        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <img
                    src="/images/navigation/options.svg"
                    alt={t("table.options")}
                    style={{ cursor: "pointer", width: "24px", height: "24px" }}
                    onClick={this.openModal}
                />
                <Modal show={isModalOpen} onClose={this.closeModal} title={t("table.options")}>
                    <div>
                        <div className="option-copy">
                            <OptionsCopy trainingId={trainingId} onTrainingOptionCopy={onTrainingOptionCopy} />
                        </div>
                        <div className="option-delete">
                            <OptionsDelete trainingId={trainingId} onTrainingOptionDelete={onTrainingOptionDelete} />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withTranslation("global")(Options);
