import React, { Component } from "react";
import OptionsDelete from "./OptionsDelete";
import OptionsMove from "./OptionsMove";
import OptionsCopy from "./OptionsCopy";
import OptionsEdit from "./OptionsEdit";
import { withTranslation } from "react-i18next";
import Modal from "../../../../../constants/Modal";

type Props = {
    exerciseId: string;
    trainingId: number;
    t: any;
    onExerciseUpdate: () => void;
};

type State = {
    isModalOpen: boolean;
};

class Options extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };
    }

    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    render() {
        const { exerciseId, trainingId, onExerciseUpdate, t } = this.props;
        const { isModalOpen } = this.state;

        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                {/* Ikona otwierająca modal */}
                <img
                    src="/images/navigation/options.svg"
                    alt="Options"
                    style={{ cursor: "pointer", width: "24px", height: "24px" }}
                    onClick={this.openModal}
                />

                {/* Modal zawierający wszystkie opcje */}
                <Modal show={isModalOpen} onClose={this.closeModal} title={t("table.options")}>
                    <div>
                        <div className="option-move"><OptionsMove exerciseId={exerciseId} trainingId={trainingId} onExerciseUpdate={onExerciseUpdate} /></div>
                        <div className="option-copy"><OptionsCopy exerciseId={exerciseId} trainingId={trainingId} onExerciseUpdate={onExerciseUpdate} /></div>
                        <div className="option-delete"><OptionsDelete exerciseId={exerciseId} trainingId={trainingId} onExerciseOptionDelete={onExerciseUpdate} /></div>
                        <div className="option-edit"><OptionsEdit exerciseId={exerciseId} trainingId={trainingId} onExerciseUpdate={onExerciseUpdate} /></div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withTranslation("global")(Options);
