import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Goal } from "../../../../types/types";


const GetGoals = ({ goals }: { goals: Goal[] }) => {
    const { t } = useTranslation("global");

    return (
        <div>
            {goals.length === 0 ? (
                <p>{t('clients.no_goals_yet')}</p>
            ) : (
                <table style={{ minWidth: '650px', width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th>{t("table.goal_type")}</th>
                            <th>{t("table.body_param")}</th>
                            <th>{t("table.body_test")}</th>
                            <th>{t("table.unit")}</th>
                            <th>{t("table.action")}</th>
                            <th>{t("table.value")}</th>
                            <th>{t("table.goal_date")}</th>
                            <th>{t("table.insert_date")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {goals.map((goal) => (
                            <tr key={goal.id} style={{ borderBottom: '1px solid #ddd' }}>
                                <td>{goal.goalType}</td>
                                <td>{goal.dictBodyParam}</td>
                                <td>{goal.dictBodyTest}</td>
                                <td>{goal.dictUnit}</td>
                                <td>{goal.action}</td>
                                <td>{goal.value}</td>
                                <td>{new Date(goal.goalDate).toLocaleDateString()}</td>
                                <td>{new Date(goal.insertDate).toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default GetGoals;