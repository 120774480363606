import axios from 'axios';
import authHeader from '../auth-header';
import { API_URL } from '../../constants/api'

class TrainingsService {

  deleteTraining(id: number) {
    return axios.delete(API_URL + '/delete_training/' + id, { headers: authHeader() })
      .then(response => {
        console.log('Training deleted successfully');
        return response.data;
      })
      .catch(error => {
        console.error('Error deleting training:', error);
        throw error;
      });
  }

  copyTraining(trainingId: number) {
    return axios.post(`${API_URL}/copy_training/${trainingId}`, {}, { headers: authHeader() });
  }
}

export default new TrainingsService()