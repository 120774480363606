import React, { Component } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BodyParamsItem } from '../../types/types';

type Props = {
  bodyParams: BodyParamsItem[] | null; // Receives updated data from parent
};

export default class ShowDiagram extends Component<Props> {
  render() {
    const { bodyParams } = this.props;

    // Sprawdzenie, czy bodyParams istnieje i zawiera dane
    if (!bodyParams || bodyParams.length === 0) {
      return <p>Brak danych do wyświetlenia wykresu.</p>;
    }
    // Group and process bodyParams data for the chart
    const groupedData: { [key: string]: BodyParamsItem[] } = {};
    bodyParams.forEach((item) => {
      if (!groupedData[item.dict_body_params_name]) {
        groupedData[item.dict_body_params_name] = [];
      }
      groupedData[item.dict_body_params_name].push(item);
    });

    // Chart line setup
    const chartLines = Object.keys(groupedData).map((paramName) => (
      <Line
        key={paramName}
        type="monotone"
        dataKey="value"
        name={paramName}
        data={groupedData[paramName]}
        stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`} // Random color
      />
    ));

    return (
      <div style={{ height: "500px", width: "100%" }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={bodyParams} // Przekazanie danych
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="insert_date" angle={-90} textAnchor="end" height={100} />
            <YAxis />
            <Tooltip />
            <Legend />
            {chartLines}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}