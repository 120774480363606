import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Service from "../../services/bodyParams";
import { BmrType } from "../../types/types";

type Props = {
    t: any;
};
type State = {
    bmrList: BmrType[] | null;
    date: string | null;
    loading: boolean;
}

class GetBmr extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            bmrList: [],
            date: null,
            loading: true
        };
    }

    componentDidMount() {
        Service.getBmr().then(
            (response) => {
                if (response.status === 200) {
                    const bmrList = response.data;
                    const date = bmrList.length > 0 ? bmrList[0].insert_date : null;
                    this.setState({ bmrList, date, loading: false });
                } else if (response.status === 204) {
                    this.setState({ bmrList: null, date: null, loading: false });
                } else {
                    console.log("Unexpected status code:", response.status);
                    this.setState({ loading: false });
                }
            },
            (error) => {
                console.log("Error fetching BMR:", error);
            }
        )
    }

    render() {
        const { bmrList, date, loading } = this.state;
        const { t } = this.props;

        if (loading) {
            return <p>
                <span className="loader" />
            </p>;
        }

        if (!bmrList) {
            return <p>
                <span className="loader" />
                {t("no_data")}</p>;
        }

        return (
            <div>
                {date && <p>{t("body_params.bmr_values_calculated_for")} {date}</p>}
                <br/>

                <table className="fixed-table">
                    <thead>
                        <tr>
                            <th className="column-medium">{t("description")}</th>
                            <th className="column-medium">{t("bmr")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bmrList.map((bmr, index) => (
                            <tr key={index}>
                                <td className="column-medium">{bmr.category}</td>
                                <td className="column-medium">{bmr.bmr}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withTranslation("global")(GetBmr);
