import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AddExercise from './addExercise';
import GetExercise from './getExercise';
import Modal from '../../../../../constants/Modal'; // Ścieżkę dostosuj do lokalizacji Twojego pliku modalnego

type Props = {
    workbook_id: number,
    training_id: number
    t: any;
}

type State = {
    refreshKey: number;
    isModalOpen: boolean;
};

class Exercise extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            refreshKey: 0,
            isModalOpen: false,
        };
    }

    handleRefresh = () => {
        this.setState((prevState) => ({
            refreshKey: prevState.refreshKey + 1,
        }));
    };

    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    render() {
        const { workbook_id, training_id, t } = this.props;
        const { refreshKey, isModalOpen } = this.state;

        return (
            <div>
                <button onClick={this.openModal}>{t('buttons.add_exercise')}</button>
                
                <Modal show={isModalOpen} onClose={this.closeModal} title="Add Exercise">
                    <AddExercise 
                        workbook_id={workbook_id} 
                        training_id={training_id} 
                        onRefresh={() => {
                            this.handleRefresh();
                            this.closeModal();
                        }} 
                    />
                </Modal>

                <GetExercise training_id={training_id} key={refreshKey} />
            </div>
        );
    }
}

export default withTranslation('global')(Exercise);
