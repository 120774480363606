import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Service from "../../services/bodyParams";
import PostGender from "./postGender";
import Modal from "../../constants/Modal";

type Props = {
  updateData: () => void;
};

const GetGender: React.FC<Props> = ({ updateData }) => {
  const [gender, setGender] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation("global");

  useEffect(() => {
    getGender();
  }, []);

  const getGender = () => {
    setLoading(true);
    Service.getGender()
      .then((response) => {
        if (response.status === 200) {
          setGender(response.data.gender);
        } else if (response.status === 204) {
          setGender(null);
        } else {
          console.log("Unexpected status code:", response.status);
        }
      })
      .catch((error) => {
        console.log("Error fetching gender:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshGender = () => {
    getGender();
    updateData();
    setIsModalOpen(false);
  };

  if (loading) {
    return <p><span className="loader" /></p>;
  }

  return (
    <div>
      <button onClick={() => setIsModalOpen(true)}>{t("buttons.update")}</button>
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)} title={t("body_params.update_gender")}>
        <PostGender onGenderUpdate={refreshGender} />
      </Modal>
    </div>
  );
};

export default GetGender;
